* {
  padding: 0;
  margin: 0;
  direction: rtl;
  text-align: right;
  font-family: "iran_sans";
}
@font-face {
  font-family: "iran_sans";
  src: local("iran_sans"), url(./assets/fonts/IRANSans.woff) format("woff");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 0 0 0;
  direction: rtl;
  text-align: right;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.color-white {
  color: #ffffff !important;
}
/* right panel icons */
.iconContainer {
  position: relative;
  width: 70px;
  height: 70px;
  /* background-color: brown; */
  margin-bottom: 5px;
  flex-shrink: 0;
  text-align: center;
  cursor: pointer;
  display: flex;
  /* flex: co; */
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.9s;
  border-radius: 5px;
}
.badge {
  position: absolute !important;
  top: 2px;
  left: 2px;
  line-height: unset !important;
}
.myBadge {
  width: 15px;
  text-align: center !important;
  height: 15px;
  font-size: 0.6rem !important;
  margin: 0px;
  padding: 2px !important;
}
.iconActive {
  background-color: rgba(0, 165, 148, 0.15);
}
.iconContainer:hover {
  background-color: rgba(0, 165, 148, 0.05);
}
.iconText {
  font-size: 0.72rem;
  text-align: center;
  margin: 0;
  font-weight: bold;
}
.iconSubtitle {
  font-size: 0.6rem;
}
.fs8rem {
  font-size: 0.8rem !important;
}

.noScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.noScrollbar::-webkit-scrollbar {
  display: none;
}

#iconsContainer {
  width: 80px;
  /* background-color: aquamarine; */
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#iconsContainer::-webkit-scrollbar {
  display: none;
}

/* map styles */
#map {
  width: 100%;
  height: 100%;
  position: relative;
  direction: ltr;
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.8rem !important;
  background-color: #00a594 !important;
  color: white !important;
  border-bottom: none !important;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popoverSelect {
  width: 276px;
  text-align: center;
  padding: 7px;
  height: 126px;
}
.mask {
  color: #00000069 !important;
  z-index: 9999999999999999999 !important;
}
.eTpeTG {
  position: absolute;
  top: 10px !important;
  right: 10px !important;
  width: 9px;
  height: 9px;
  color: inherit !important;
}
.cVioWK {
  z-index: 999999 !important;
}
.bRLMPP {
  font-family: inherit !important;
  background: #00a594 !important;
}
.popoverSelect .table td,
.popoverSelect .table th {
  padding: 0.2rem;
  vertical-align: middle;
  font-size: 0.7rem;
  /* border-top: 1px solid #dee2e6; */
}
.popoverSelect button,
.default-btn,
.dashboard button {
  border: 1px solid #48a594 !important;
  color: #48a594 !important;
  font-size: 0.7rem !important;
  padding: 0.25rem 0.8rem !important;
}
.dashboardTimeField {
  /* width: 100%; */
  height: 40px;
  border: 1px solid white;
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: #06ceb5;
  border-color: #06ceb5;
  margin: 16px;
}
.mySelect {
  background: #00d3b5;
  border: 1px solid #00d3b5;
  display: flex;
  /* width: 100%; */
  height: 40px;
  color: white;
  margin: 16px;
  border-radius: 8px;
  padding: 8px;
}

.dashboardTimeField:hover {
  background-color: unset;
  border-color: white;
}
.popoverSelect button:hover,
.default-btn:hover {
  background-color: #48a594 !important;
  color: white !important;
}
.popoverClose {
  cursor: pointer;
  font-size: 1rem;
}
.ol-overlaycontainer-stopevent .popover-body {
  width: 276px !important;
  padding: 0 !important;
}
div[class^="ol"] {
  direction: ltr;
}
.ol-tooltip {
  background: "#00a594ba" !important;
}
.ol-tooltip-static {
  background-color: #00a594 !important;
  color: rgb(253, 253, 253) !important;
  border: 1px solid white !important;
}
.ol-tooltip-static:before {
  border-top-color: #00a594 !important;
}
.ol-rotate {
  position: unset !important;
}
.hidden {
  display: none !important;
}
#mapFrame {
  /* width: 100%; */
  height: calc(100vh - 24px);
  /* border: aqua solid 1px; */
  border-radius: 15px;
  overflow: hidden;
  flex: 1;
  /* mapres */
  height: 100%;
}
#rightPanel {
  width: 80px;
  height: calc(100vh - 24px);
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  z-index: 100;
  background-color: white;
}
#rightPanel::-webkit-scrollbar {
  display: none;
}

.my-form-control {
  height: 36px;
  background: rgba(0, 0, 0, 0.05);
  color: #000;
  font-size: 16px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid transparent;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 100%;
  border-radius: 4px;
}
.my-form-group {
  margin-bottom: 0.8rem;
}
.my-submit {
  width: 100%;
  height: 2rem;
  background: #00a594 !important;
  border: 1px solid #00a594 !important;
  color: #fff !important;
  text-align: center;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-submit:disabled {
  cursor: not-allowed;
  opacity: 80%;
}

.my-form-control:focus,
.my-form-control:active {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.07);
  border-color: transparent;
  color: #818585;
}
.my-form-link {
  text-decoration: none;
  color: #00a994;
  font-weight: bold;
  font-size: 0.8rem;
}
.textShadow {
  text-shadow: 1px 1px 0px #fff;
}

#authBackground {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 180, 216);
  background: linear-gradient(
    90deg,
    rgba(0, 180, 216, 1) 0%,
    rgba(144, 224, 239, 1) 100%
  );
  background-image: url("assets/images/shomal.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
}
.authHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 16px;
}
.authHeader h4 {
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: 16px;
  color: black;
}
.authCard {
  width: 400px;
  min-height: 30vh;
  text-align: center;
  justify-content: center;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 10px 34px -15px rgb(0 0 0 / 24%);
}
.authIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #00a994;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.authTitle {
  margin: 16px;
  font-weight: bold;
  font-size: 0.9rem;
  color: #00a994;
}
.avatar {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: blueviolet;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.avatar * {
  color: white;
  font-weight: bold;
}
.profileTitle {
  text-align: center !important;
  font-weight: bold;
  color: rgb(0, 180, 216);
}

#logoContainer {
  text-align: center;
}
.drawersContainer {
  width: 0;
  position: fixed;
  right: 86px;
  top: 18px;
  z-index: 999999;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.legendAnalysisContainer {
  width: 250px;
  /* height: calc(100vh - 36px); */
  max-height: calc(100vh - 36px);
  border-radius: 15px;
  transition: 0.9s;
  padding: 0;
  z-index: 999999;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.legendAnalysisNav {
  height: 50px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-around;
  margin: 0 10px 0 10px;
}
.legendAnalysisTab {
  height: 40px;
  border-bottom: 1.5px solid #b8b5b8;
  line-height: 40px;
  flex: 1;
  text-align: center;
  font-size: 0.72rem;
  cursor: pointer;
}
.legendAnalysisActiveTab {
  height: 40px;
  border-bottom: 3px solid #00a594;
  color: #00a594;
  line-height: 40px;
  flex: 1;
  text-align: center;
  font-size: 0.72rem;
  cursor: pointer;
}

.legendAnalysisContent {
  background-color: white;
  text-align: center;
  margin: 0 3px 0 3px;
  overflow-y: auto;
  flex: 1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.legendAnalysisContent::-webkit-scrollbar {
  display: none;
}
.legendAnalysisToggle {
  width: 100%;
  background-color: #00a594;
  border-radius: 0 0 15px 15px;
  justify-self: flex-end;
  flex-shrink: 0;
  text-align: center;
  color: white;
  font-size: 0.8rem;
}
.drawer {
  width: 250px;
  height: calc(100vh - 36px);
  border-radius: 15px;
  transition: 0.9s;
  margin-left: 8px;
  padding: 15px 10px 10px 10px;
  z-index: 999999;
  background-color: white;
  border: 1px solid #bcbdbd;
  /* overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; */
}

.AnimatedDrawer {
  width: 250px;
  height: calc(100vh - 36px);
  border-radius: 15px;
  margin-left: 8px;
  padding: 15px 10px 10px 10px;
  z-index: 99;
  background-color: white;
  border: 1px solid #bcbdbd;
  position: fixed;
  right: 86px;
  top: 18px;
  transition: all 0.75s ease;
  display: flex;
  flex-direction: column;
}
.AnimatedDrawerClose {
  right: -250px;
}

.scroll {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.drawerTimelineOpened {
  height: calc(100vh - 86px);
}
.legendAnalysisContainerTimelineOpened {
  max-height: calc(100vh - 86px);
}
.drawerMobile {
  display: none;
}
.drawer::-webkit-scrollbar,
.seachResaultsPanel::-webkit-scrollbar,
.monthPickerListContainer::-webkit-scrollbar,
.layersPanel::-webkit-scrollbar {
  display: none;
}
.drawer-title {
  color: #00a594;
  font-size: 0.76rem !important;
  font-weight: bold !important;
}
.rmdp-ym .rmdp-day span {
  text-align: center !important;
}
.filterBottom {
  width: 100%;
  background-color: #f7f7f7;
  height: 42px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.filterBottom:hover {
  background-color: rgba(0, 139, 139, 0.281);
}

.Paginator {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 8px !important;
}
.page-link {
  font-size: 0.8rem !important;
  padding: 6px !important;
}

.search {
  position: fixed;
  z-index: 99;
  left: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  width: 300px;
  /* height: 200px; */
  /* background-color: white; */
  /* border: 1px solid; */
}
.searchBar {
  background: url(assets/images/srch.svg) 10px center no-repeat;
  border-radius: 7px !important;
  background-color: white;
}
.seachResaultsPanel {
  width: 100%;
  height: 220px;
  background-color: #fff;
  border-radius: 7px;
  margin-top: 7px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.prductGroupTitle {
  color: #747070;
  font-size: 0.74rem;
  font-weight: bold;
  margin-bottom: 6px;
}
.productTitle {
  color: #747070;
  font-size: 0.7rem;
  font-weight: bold;
  margin-right: 6px;
  cursor: pointer;
}
.productInfoIcon {
  color: #747070;
  cursor: pointer;
  margin: 0 5px;
}
.modal-body {
  font-size: 0.8rem;
}
.modal-backdrop,
.modal {
  z-index: 99999999 !important;
}
.modal-header {
  background-color: #48a594;
  color: white;
}
.productSubTitle {
  /* margin-right: 30px; */
  font-size: 0.65rem;
  color: #747070;
  opacity: 0.8;
}
.ProductLegend {
  border: 1px solid #b8b5b8;
  border-radius: 5px;
  width: 100%;
  padding: 0;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  z-index: 99999999;
  background: white;
}
.productLegendNav {
  margin: 4px;
}
.productLegendContent {
  text-align: center;
  margin: 0 5px 0 5px;
  /* flex: 1; */
  /* min-height: 100px; */
}

.productLegendToggle {
  width: 100%;
  border-radius: 0 0 5px 5px;
  background-color: #f3f3f3;
  text-align: center;
  font-size: 0.5rem;
}
.hr {
  width: 100%;
  border-top: 1px solid #e0dddd;
  margin: 8px 0;
}
.LayerTimePicker {
  display: flex;
  border: 1px solid #00a594;
  border-radius: 8px;
  height: 36px;
  align-items: center;
  justify-content: center;
}
.LayerTimePickerItem {
  flex: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.rotate-180 {
  transform: rotate(180deg);
}
.LayerTimePickerItemActive {
  background: #00a594;
  cursor: pointer;
  color: white;
  font-size: 0.7rem;
  font-weight: bold;
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.modal-90w {
  width: 90vw !important;
}
.VectorChangeRow .modal-dialog {
  max-width: unset !important;
}
.LayerTimePickerItemActive:hover {
  background: #00a594c9;
}
.dashboard {
  width: 65vw;
  height: calc(100vh - 24px);
  margin: 12px;
  /* padding: 0 10px 0 10px; */
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-right: 0;
  z-index: 999999;
  background-color: white;
}
.AnimatedDashboard {
  position: relative;
  width: 65vw;
  height: calc(100vh - 24px);
  margin: 12px;
  /* padding: 0 10px 0 10px; */
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid rgb(219, 219, 219);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-right: 0;
  z-index: 99;
  background-color: white;
  transition: all 0.75s ease;
}
.AnimatedDashboardClose {
  right: -65vw;
  width: 0;
  margin: 0;
}
#dashboardFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem;
  background-color: #00a594;
}
.loading {
  position: relative;
  padding: 32px;
  /* position: absolute; */
  /* width: 100%;
  height: 100%; */
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #e2ebe977;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scentece {
  font-size: 0.8rem;
  text-align: justify;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
}
.carousel-control-next,
.carousel-control-prev {
  top: -7px !important;
}
.carousel-control-next,
.carousel-control-prev {
  color: black im !important;
}
.monthPickerMonth:hover {
  background-color: #48a594a4;
  color: white;
}
.monthPickerListContainer {
  max-height: 134px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.chartFrame {
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid rgb(219, 219, 219);
  padding: 10px;
  font-size: 0.7rem;
}
.dashboard::-webkit-scrollbar {
  display: none;
}
.comment {
  font-size: 0.65rem;
}
.analysisMode {
  cursor: pointer;
}
.overflow-y-auto {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflow-y-auto::-webkit-scrollbar {
  display: none;
}
.ol-print {
  display: none !important;
}

/* td {
  border: 1px solid black;
} */
.printBackground {
  background-color: white;
  width: 5px;
  height: 5px;
}
#printFooter td {
  width: 25%;
}
.ol-overviewmap-map {
  width: 100px;
  height: 100px;
}
.prtOvr button {
  display: none !important;
}
.dashboardWraper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.dashboardWraper::-webkit-scrollbar {
  display: none;
}
.dashboardHeader {
  background-color: #00a594;
  width: 100%;
  /* height: 180px; */
  border-radius: 5px 5px 0 0;
  padding: 15px;
  border-bottom: white 1px solid;
}
.dashboard-hr {
  margin: 5px 15px;
  border: 0;
  border-top: 1px solid rgb(232 232 232 / 48%);
}
.dashboard form label {
  font-size: 0.68rem;
  color: white;
  font-weight: inherit;
  margin-bottom: 0.3rem;
  margin-right: 12px;
}
.dashboard .form-control-sm {
  /* height: calc(1.2em + 0.5rem + 2px); */
  padding: 0.2rem 0.5rem;
  font-size: 0.785rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.dashboardHeader p {
  font-size: 0.65rem;
  color: white;
  text-align: justify;
  margin: 0;
  margin-right: 3px;
  margin: 2;
}
.dashboardContent {
  width: 100%;
  flex: 1;
  /* background-color: aquamarine; */
  border-radius: 0 0 5px 5px;
}
.tabItem {
  border: none;
  background-color: #00a594;
}
.tab-pane {
  padding: 15px;
}
.nav-tabs .nav-link {
  border: none !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
  font-size: 0.75rem;
}
.nav {
  background-color: #00a594;
  padding-right: 50px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-radius: 0;
}
.nav-link {
  padding: 0.4rem 1.1rem !important;
}
.tesst {
  /* background-color: violet; */
  width: 100px;
  height: 2500px;
  /* background-color: #48a594; */
}
.nav-tabs {
  border-bottom: 1px solid #48a594 !important;
}
.avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: #00a594 2px solid;
}

/* bottom navigation */
#bottomNavigation {
  width: 100%;
  height: 60px;
  display: none;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: 100;
  background-color: white;
}
.authPageWrapper {
  width: 100vw;
  height: 100vh;
  background-color: white;
  padding: 10px;
}
.authBackgroundWrapper {
  border-radius: 10px;
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.authLogoWraper {
  width: 100px;
  height: 60px;
  position: fixed;
  top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.authLogo {
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 0px 0px 10px 10px;
}

.authFormWrapper {
  width: 300px;
  /* height: 300px; */
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
}
.authNavTabs {
  border-bottom: 1px solid #fff !important;
}
.authNavTabs .nav-item .active {
  border-bottom: 2px solid #48a594 !important;
}
.authNavTabs .nav-item {
  /* border-bottom: 1px solid #b8b5b8 !important; */
}
.authNavTabs .active {
  color: #48a594 !important;
}
.authNavTabs .nav-link {
  border-bottom: 1px solid #b8b5b8 !important;
  color: #b8b5b8;
}
.authFormInput {
  font-size: 0.6rem !important;
  padding: 1rem !important;
  background-color: #f7f7f7 !important;
  border-color: #d8d8d8 !important;
  color: #626464 !important;
  /* margin-bottom: 1.4rem; */
}
.form-error {
  font-size: 0.62rem;
}
.authSubmitBtn {
  width: 100% !important;
  background: #48a594 !important;
  font-size: 0.75rem !important;
  color: white !important;
  margin-bottom: 1.4rem;
}
.authFormText {
  font-size: 0.6rem;
  margin-right: 1.2rem;
}
.toolsContainer {
  position: fixed;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 98;
}
.tools {
  background-color: white;
  border-radius: 7px;
  width: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tool {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.tool .ol-control button {
  margin: 0;
  font-size: unset;
  font-weight: unset;
  height: unset;
  width: unset;
  line-height: unset;
  background-color: white;
}
.tool .ol-control button:hover {
  background-color: white;
}
.ol-rotate {
  display: none;
}
.tool .ol-rotate {
  display: block;
}
#mouse-position {
  position: fixed;
  z-index: 97;
  height: 30px;
  min-width: 180px;
  border-radius: 7px;
  background-color: white;
  /* color: #747070; */
  bottom: 68px;
  left: 20px;
  text-align: center !important;
  font-size: 0.65rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 8px;
}
.timeline-opened {
  width: calc(100% - 240px);
  transition: width 2s;
}
.timeline-closed {
  width: auto;
  transition: width 2s;
}
.timeline {
  height: 40px;
  position: fixed;
  left: 150px;
  bottom: 20px;
  align-items: center;
  direction: ltr;
  background-color: #ffffff;
  z-index: 98;
  border-radius: 7px;
}
.ol-mouse-position {
  width: 100%;
  text-align: center;
  height: 100%;
  line-height: 30px;
  position: unset !important;
}
#scalebar {
  position: fixed;
  z-index: 97;
  color: #00a594;
  bottom: 68px;
  left: 210px;
}
.productInfo {
  color: #00a594;
  font-size: 0.75rem;
  text-align: justify;
}
.ol-scale-line {
  position: unset !important;
}
.layersCustomButton {
  width: 120px;
  position: fixed;
  border-radius: 7px;
  background-color: white;
  cursor: pointer;
  min-width: 70px;
  height: 40px;
  left: 20px;
  bottom: 20px;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  padding: 3px;
  overflow: hidden;
}
.layersCustomButtonIcon {
  width: 34px;
  height: 34px;
  border-radius: 7px;
}
.layersCustomButtonTextActive {
  color: #00a594;
}
.layersPanel {
  display: block;
  position: fixed;
  left: 20px;
  bottom: 68px;
  width: 180px;
  height: 220px;
  background-color: white;
  border-radius: 5px;
  z-index: 100;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.baseLayerIcon {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 7px;
}
.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* measure styles */
.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  z-index: 99;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}
.dot-label {
  font-size: 10px;
  padding-bottom: 8px !important;
  /* color: #00a594 !important; */
}
.present {
  font-weight: bold;
  color: #00a594 !important;
}
.button-back,
.button-forward {
  border: none !important;
}
.events,
.button-back,
.button-forward {
  top: 28px !important;
}
/* Animations */
.my-node-enter {
  height: 0;
}
.my-node-enter-active {
  height: 220px;
  transition: height 400ms ease-out;
}
.my-node-exit {
  height: 220px;
}
.my-node-exit-active {
  height: 0;
  transition: height 400ms ease-out;
}
.thumbnail-div {
  width: 220px;
  min-height: 256px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* .slideHeight-enter {
  height: 0;
}
.slideHeight-enter-active {
  height: unset;
  transition: height 400ms ease-out;
}
.slideHeight-exit {
  height: unset;
}
.slideHeight-exit-active {
  height: 0;
  transition: height 400ms ease-out;
} */

.slideRL-enter {
  width: 200px;
}
.slideRL-enter-active {
  width: 70%;
  transition: width 400ms linear;
}
.slideRL-exit {
  width: 70%;
}
.slideRL-exit-active {
  width: 200px;
  transition: width 400ms linear;
}

.alert-danger {
  background-color: #ff5d6c !important;
  border-color: #fd9ea8 !important;
}
.mobileControlButton {
  display: none;
}
#timeLineToggleBtn {
  width: 25px;
  height: 34px;
  margin: 3px;
  background-color: rgb(242 242 242);
  border-radius: 0 7px 7px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #48a594;
}
#printButton {
  display: block;
}
@media only screen and (max-width: 600px) {
  .my-form-control:focus,
  .my-form-control:active {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.7);
    border-color: transparent;
    color: #818585;
  }
  .authCard {
    background: none;
    border: 1px solid white;
    width: 90%;
  }
  .my-form-control {
    background: rgba(255, 255, 255, 0.8);
  }
  #printButton {
    display: none;
  }
  #fakeLabel {
    display: none;
  }
  .layersPanel {
    display: none;
  }
  .baseLayerIcon {
    width: 50%;
    height: auto;
    margin-bottom: 10px;
  }
  .timeline-opened {
    width: calc(100% - 24px);
  }
  .timeline-closed {
    width: auto;
  }
  .timeline {
    max-width: calc(100% - 24px);
    height: 40px;
    position: fixed;
    left: 12px;
    bottom: 66px;
    align-items: center;
    direction: ltr;
    background-color: #ffffff;
    z-index: 98;
    border-radius: 7px;
  }
  .toolsContainer {
    position: fixed;
    left: 20px;
    top: 30%;
    transform: translateY(-30%);
    z-index: 98;
  }
  #timeLineToggleBtn {
    display: none;
  }
  #mouse-position {
    bottom: 112px;
    /* left: 200px; */
  }
  #scalebar {
    bottom: 112px;
    left: 50%;
    transform: translateX(50%);
  }
  .layersCustomButton {
    display: none;
  }
  .legendAnalysisContainer {
    display: none;
  }
  .drawer {
    width: unset;
    height: 50%;
    position: fixed;
    right: 12px;
    left: 12px;
    bottom: 66px;
    top: unset;
    margin-left: unset;
    border-radius: 7px;
  }
  .AnimatedDrawer {
    width: calc(100vw - 24px);
    right: 12px;
    height: 50%;
    position: fixed;
    /* right: 12px; */
    /* left: 12px; */
    bottom: 66px;
    top: unset;
    margin-left: unset;
    border-radius: 7px;
  }
  .AnimatedDrawerClose {
    right: 12px;
    bottom: calc(-50vh - 66px);
  }
  .AnimatedDashboard {
    width: calc(100vw - 24px);
    right: 12px;
    height: 50%;
    /* height: calc(100vh - 78px); */
    position: fixed;
    /* right: 12px; */
    /* left: 12px; */
    bottom: 66px;
    top: unset;
    margin-left: unset;
    border-radius: 7px;
    margin: unset;
  }
  .AnimatedDashboardClose {
    right: 12px;
    bottom: calc(-50vh - 66px);
    /* width: 0;
  margin: 0; */
  }
  .drawerFullScreen {
    height: 70%;
  }
  .dashboard {
    width: unset;
    margin: 0;
    height: 70%;
    position: fixed;
    right: 12px;
    left: 12px;
    bottom: 66px;
    top: unset;
    border-radius: 7px;
    border-color: white;
  }
  #dashboardFooter {
    display: none;
  }
  .iconContainer {
    width: 70px;
    height: 58px;
    flex-shrink: 0;
    margin-bottom: 0;
    /* background-color: teal; */
  }
  #mapFrame {
    height: calc(100vh - 66px);
    border-radius: 7px;
  }
  #bottomNavigation {
    display: flex;
    justify-content: space-around;
  }
  #rightPanel {
    display: none;
  }
  /* mobile */
  .mobileControlButton {
    display: block;
    margin: 5px;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  .mobileControlButton img {
    width: 25px;
    height: 25px;
    margin: 3px;
  }
  .drawerMobile {
    display: block;
  }
  .search {
    display: none;
  }
}
