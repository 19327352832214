.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ISA-card {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.ISA-bg-green {
  background-color: #00a594;
}
.ISA-color-green {
  color: #00a594;
}
.ISA-color-gray {
  color: #747070;
}
#main_container {
  width: 100%;
  display: flex;
  flex: 1;
  background-color: white;
}
#main_map_container {
  height: 100vh;
  flex: 1;
  /* background-color: aliceblue; */
  padding: 12px 0 12px 12px;
}
@media only screen and (max-width: 600px) {
  #main_container {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    /* mapres */
    height: 100vh;
  }
  #main_map_container {
    height: 70vh;
    padding: 6px 6px 0 6px;
    /* mapres */
    height: unset;
  }
}
